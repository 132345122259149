import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from 'lang/en'
import es from 'lang/es'
import pt from 'lang/pt'

const resources = {
    en: {
        translation: en
    },
    es: {
        translation: es
    },
    pt: {
        translation: pt
    }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: false,
    load: 'languageOnly',
    supportedLngs: ['en', 'es', 'pt'],
    fallbackLng: false,
    keySeparator: '.',
    detection: {
        order: ['localStorage', 'cookie'],
        caches: ['localStorage', 'cookie'],
        excludeCacheFor: [],
    }
  });

export default i18n;