import { useState, useEffect } from "react";

const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0); // setar forma da variavel

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        }

        window.addEventListener('scroll', handleScroll); // monitorar o position
        
        handleScroll();

        return () => window.removeEventListener('scroll', handleScroll); // remover event listener
    }, []);

    return scrollPosition; // retornar o estado para o componente
}

export default useScrollPosition;