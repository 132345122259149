import { useTranslation } from "react-i18next";
import { Heading, Text, ImageSrcSet } from "components/common";
import { destinationImages } from "constants";

const Destination = () => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col md:flex-row space-between mb-0">
            <div className="basis-7/12 order-last md:order-first drop-shadow-section w-full md:w-80vh">
                <ImageSrcSet
                    base={destinationImages.base}
                    sm={destinationImages.sm}
                    lg={destinationImages.lg}
                    className="h-full w-full object-center object-scale-down md:object-fill aspect-auto"
                    alt="Praia Sâo Sebastiâo"
                />
            </div>
            <div className="basis-5/12 order-first md:order-last flex flex-col px-12 my-10 mx-auto w-full md:w-60vh">
                <Heading
                    className="text-3xl sm:text-4xl md:text-5xl mb-4 border-b-4 border-main-solid pb-2"
                    tag="h2"
                >
                    {t('destination.title')}
                </Heading>
                <Text className="mb-2 leading-7 text-xl md:text-xl opacity-75 tracking-wider">
                    {t('destination.subtitle')}
                </Text>
                <div className="mt-8 max-w-60vh">
                    <Text className="mb-3 leading-7 text-lg opacity-75">
                        {t('destination.paragraph1')}
                    </Text>
                    <Text className="mb-3 leading-7 text-lg opacity-75">
                        {t('destination.paragraph2')}
                    </Text>
                    <Text className="mb-3 leading-7 text-lg opacity-75">
                        {t('destination.paragraph3')}
                    </Text>
                </div>
            </div>
        </div>
    )
}

export default Destination;