import imgSaoSebastiao from 'assets/images/sao_sebastiao.webp';
import imgSaoSebastiaoSm from 'assets/images/sao_sebastiao_sm.webp';
import imgSaoSebastiaoXs from 'assets/images/sao_sebastiao_xs.webp';

const destinationImages = {
    base: imgSaoSebastiaoXs,
    sm: imgSaoSebastiaoSm,
    lg: imgSaoSebastiao,
}

export default destinationImages;