import { useTranslation, Trans } from 'react-i18next';
import { Heading, Text } from 'components/common';

const HowToGetThere = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="flex flex-col sm:flex-row space-between mb-0 pb-2 w-full bg-white">
                <div className="flex flex-col px-12 mt-16 mb-10 mx-auto w-full text-center">
                    <Heading
                        className="text-3xl sm:text-4xl md:text-5xl mb-4 pb-2"
                        tag="h2"
                    >
                        {t('instructions.title')}
                    </Heading>
                    <div className="max-w-100vh sm:max-w-80vh mx-auto">
                        <Text className="mb-3 leading-7 text-lg opacity-75">
                            <Trans
                                i18nKey="instructions.subtitle.paragraph1"
                                components={{
                                    style_anchor: <span className="font-bold"/>
                                }}
                            />
                        </Text>
                        <Text className="mb-3 leading-7 text-lg opacity-75">
                            {t('instructions.subtitle.paragraph2')}
                        </Text>
                        <Text className="mb-3 leading-7 text-lg opacity-75">
                            {t('instructions.subtitle.paragraph3')}
                        </Text>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="flex flex-col md:flex-row space-between py-12 pb-2 w-full bg-main-background">
                    <div className="basis-6/12 flex flex-col px-12 my-10 mx-auto w-full md:w-50vh">
                        <Heading
                            className="text-3xl sm:text-4xl md:text-5xl mb-4 border-b-2 border-main-solid pb-2"
                            tag="h2"
                        >
                            {t('instructions.car.title')}
                        </Heading>
                        <div className="mt-4">
                            <Text className="mb-3 leading-7 text-lg opacity-75">
                                {t('instructions.car.paragraph1')}
                            </Text>
                            <Text className="mb-3 leading-7 text-lg opacity-75">
                                {t('instructions.car.paragraph2')}
                            </Text>
                            <Text className="mt-6 mb-3 leading-7 text-lg opacity-75">
                                <a
                                    href="https://goo.gl/maps/7Si2gQ37e5uCw3SX7"
                                    className="text-center leading-8  underline underline-offset-4 font-bold opacity-85"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t('instructions.car.mapsLink')}
                                </a>
                            </Text>
                            <div className="w-full relative overflow-hidden pb-55vh h-0">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d468271.31506284507!2d-46.227441737863046!3d-23.519203720860663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x94ce8ae89ffd06a7%3A0x6b960ce636f632dd!2sAeroporto%2C%20Guarulhos%20-%20State%20of%20S%C3%A3o%20Paulo%2C%20Brazil!3m2!1d-23.430374999999998!2d-46.4729592!4m5!1s0x94d29b99ed6db291%3A0xcf7d42563c773ba3!2sEspa%C3%A7o%20Abric%C3%B3%20Eventos%20-%20Rod.%20Dr.%20Manoel%20Hip%C3%B3lito%20do%20R%C3%AAgo%2C%202354%20-%20Praia%20do%20Arrast%C3%A3o%2C%20S%C3%A3o%20Sebasti%C3%A3o%20-%20SP%2C%2011605-136%2C%20Brazil!3m2!1d-23.769893!2d-45.4036956!5e0!3m2!1sen!2sus!4v1662262657791!5m2!1sen!2sus" width="100%" height="100%" className="absolute left-0 right-0 border border-2 border-main-solid rounded-lg " allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="basis-6/12 flex flex-col px-12 my-10 mx-auto w-full md:w-50vh">
                        <Heading
                            className="text-3xl sm:text-4xl md:text-5xl mb-4 border-b-2 border-main-solid pb-2"
                            tag="h2"
                        >
                            {t('instructions.other.title')}
                        </Heading>
                        <div className="mt-4">
                            <div>
                                <Heading className="text-3xl mb-3">
                                    {t('instructions.other.bus.title')}
                                </Heading>
                                <Text className="mb-3 leading-7 text-lg opacity-75">
                                    {t('instructions.other.bus.paragraph1')}
                                </Text>
                                <Text className="mb-3 leading-7 text-lg opacity-75 break-words">
                                    {t('instructions.other.bus.paragraph2')} <a
                                        href="https://www.passaromarron.com.br/"
                                        className="text-center leading-8  underline underline-offset-4 font-bold opacity-85"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        https://www.passaromarron.com.br/
                                    </a>.
                                </Text>
                                <Text className="mb-3 leading-7 text-lg opacity-75">
                                    {t('instructions.other.bus.paragraph3')}
                                </Text>
                                <Text className="mb-3 leading-7 text-lg opacity-75 break-words">
                                    {t('instructions.other.bus.paragraph4')} <a
                                        href="https://rodoviariadotiete.com/deonibus"
                                        className="text-center leading-8  underline underline-offset-4 font-bold opacity-85"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        https://rodoviariadotiete.com/deonibus
                                    </a>.
                                </Text>
                            </div>
                            <div className="mt-12">
                                <Heading className="text-3xl mb-3">
                                    {t('instructions.other.uber.title')}
                                </Heading>
                                <Text className="mb-3 leading-7 text-lg opacity-75">
                                    {t('instructions.other.uber.paragraph1')}
                                </Text>
                            </div>
                            <div className="mt-12">
                                <Heading className="text-3xl mb-3">
                                    {t('instructions.other.taxi.title')}
                                </Heading>
                                <Text className="mb-3 leading-7 text-lg opacity-75">
                                    {t('instructions.other.taxi.paragraph1')}
                                </Text>
                                <a
                                    href="tel:+551124407070"
                                    className="mb-3 leading-7 text-lg text-blue-600 underline underline-offset-4"
                                >
                                    +55 (11) 2440-7070
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HowToGetThere;