import PropTypes from 'prop-types';
import { Header, Footer } from 'components/layout';

const ApplicationLayout = ({children}) => {
    return (
        <div className="App min-h-screen bg-main-background">
            <Header />
            <main>
                {children}
            </main>
            <Footer />
        </div>
    )
}

ApplicationLayout.propTypes = {
    children: PropTypes.element.isRequired,
}

export default ApplicationLayout;