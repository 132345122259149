import classNames from 'classnames';
import { Tab } from '@headlessui/react'
import { useTranslation } from 'react-i18next';

import { Heading, Text } from "components/common";
import { HostingOptionCard } from 'components/home';
import { hostingOptions } from 'constants';

const Lodging = () => {

    const { t } = useTranslation();
    const getHospitalityAvailabilityText = (availableAt) => {
        const today = new Date();
        const curMonth = today.getMonth();
        const curYear = today.getFullYear();
        if (
            availableAt.year < curYear ||
            (availableAt.year === curYear && curMonth >= availableAt.month)
        ) {
            return t('hospitality.reservationAvailability.available');
        }
    
        return  t('hospitality.reservationAvailability.notAvailable', {
            month: `$t(months.m${availableAt.month})`,
            year: availableAt.year,
            interpolation: {
                skipOnVariables: false
            }
        });
    }
    
    return (
        <div className="flex flex-col sm:flex-row space-between mt-12 pb-2 w-full bg-white">
            <div className="flex flex-col px-2 sm:px-12 my-10 mx-auto w-full text-center">
                <Heading
                    className="text-3xl sm:text-4xl md:text-5xl mb-4 pb-2"
                    tag="h2"
                >
                    {t(`hospitality.title`)}
                </Heading>
                <div className="block w-full border sm:text-sm px-2 py-8 sm:px-0 sm-py-16 border-0">
                    <div className="max-w-4xl mx-auto">
                        <Tab.Group>
                            <Tab.List className="flex space-x-1 rounded-xl bg-gray-100 mb-12 p-1">
                                {['airbnb', 'inns', 'hostels', 'hotels'].map((category) => (
                                    <Tab
                                        key={category}
                                        className={({ selected }) =>
                                            classNames(
                                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-700',
                                                {
                                                    'bg-white shadow focus:ring-main-solid focus:outline-main-solid': selected,
                                                    'hover:opacity-50': !selected
                                                }
                                            )
                                        }
                                    >
                                        {t(`hospitality.${category}.tab`)}
                                    </Tab>
                                ))}
                            </Tab.List>
                            <Tab.Panels className="px-2 sm:px-12">
                                <Tab.Panel>
                                    <Text className="mb-6 leading-7 text-xl opacity-75">
                                        {t(`hospitality.airbnb.title`)}
                                    </Text>
                                    <Text className="mb-6 leading-7 text-lg opacity-75">
                                        <a
                                            href="https://www.airbnb.com/s/Rodovia-Doutor-Manoel-Hip%C3%B3lito-do-R%C3%AAgo--2354-~-Arrast%C3%A3o--S%C3%A3o-Sebasti%C3%A3o-~-State-of-S%C3%A3o-Paulo--Brazil/homes?adults=2&tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_lengths%5B%5D=one_week&price_filter_input_type=0&search_type=user_map_move&query=Rodovia%20Doutor%20Manoel%20Hip%C3%B3lito%20do%20R%C3%AAgo%2C%202354%20-%20Arrast%C3%A3o%2C%20S%C3%A3o%20Sebasti%C3%A3o%20-%20State%20of%20S%C3%A3o%20Paulo%2C%20Brazil&place_id=ChIJI24e8pmb0pQR4Aw0vn512QA&date_picker_type=calendar&source=structured_search_input_header&ne_lat=-23.724463896316003&ne_lng=-45.337461773210975&sw_lat=-23.79060910984536&sw_lng=-45.43693954115531&zoom=14&search_by_map=true"
                                            className=" block text-center leading-8  underline underline-offset-4 font-bold opacity-85"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {t('hospitality.airbnb.link')}
                                        </a>
                                    </Text>
                                </Tab.Panel>
                                <Tab.Panel>
                                    <Text className="mb-8 leading-7 text-xl opacity-75">
                                        {t(`hospitality.inns.title`)}
                                    </Text>
                                    <div className="overflow-hidden bg-white shadow sm:rounded-md">
                                        <ul role="list" className="divide-y divide-gray-200">
                                            {hostingOptions.inns.map((inn) => (
                                                <li key={inn.title}>
                                                    <HostingOptionCard
                                                        website={inn.website}
                                                        url={inn.url}
                                                        urlSource={inn.urlSource}
                                                        email={inn.email}
                                                        whatsapp={inn.whatsapp}
                                                    >
                                                        {{
                                                            title: <Text className="text-lg opacity-75 font-bold">{inn.title}</Text>,
                                                            reservationAvailability: getHospitalityAvailabilityText(inn.availableAt)
                                                        }}
                                                    </HostingOptionCard>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Tab.Panel>
                                <Tab.Panel>
                                    <Text className="mb-8 leading-7 text-xl opacity-75">
                                        {t(`hospitality.hostels.title`)}
                                    </Text>
                                    <div className="overflow-hidden bg-white shadow sm:rounded-md">
                                        <ul role="list" className="divide-y divide-gray-200">
                                            {hostingOptions.hostels.map((hostel) => (
                                                <li key={hostel.title}>
                                                    <HostingOptionCard
                                                        website={hostel.website}
                                                        url={hostel.url}
                                                        urlSource={hostel.urlSource}
                                                        email={hostel.email}
                                                        whatsapp={hostel.whatsapp}
                                                    >
                                                        {{
                                                            title: <Text className="text-lg opacity-75 font-bold">{hostel.title}</Text>,
                                                            reservationAvailability: getHospitalityAvailabilityText(hostel.availableAt)
                                                        }}
                                                    </HostingOptionCard>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Tab.Panel>
                                <Tab.Panel>
                                <Text className="mb-8 leading-7 text-xl opacity-75">
                                    {t(`hospitality.hotels.title`)}
                                </Text>
                                <div className="overflow-hidden bg-white shadow sm:rounded-md">
                                    <ul role="list" className="divide-y divide-gray-200">
                                        {hostingOptions.hotels.map((hotel) => (
                                            <li key={hotel.title}>
                                                <HostingOptionCard
                                                    website={hotel.website}
                                                    url={hotel.url}
                                                    urlSource={hotel.urlSource}
                                                    email={hotel.email}
                                                    whatsapp={hotel.whatsapp}
                                                >
                                                    {{
                                                        title: <Text className="text-lg opacity-75 font-bold">{hotel.title}</Text>,
                                                        reservationAvailability: getHospitalityAvailabilityText(hotel.availableAt)
                                                    }}
                                                </HostingOptionCard>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Lodging;