import PropTypes from 'prop-types';

const ImageSrcSet = ({base, sm, lg, className, alt, onLoad}) => {
    return (
        <img
            srcSet={`${base} 481w, ${sm} 769w, ${lg} 1200w`}
            sizes={`(max-width: 450px) 450px, (max-width: 769px) 769px, 1200px`}
            src={base}
            alt={alt}
            className={className}
            onLoad={onLoad}
        />
    )
}

ImageSrcSet.propTypes = {
    base: PropTypes.string.isRequired,
    sm: PropTypes.string.isRequired,
    lg: PropTypes.string.isRequired,
    className: PropTypes.string,
    alt: PropTypes.string,
    onLoad: PropTypes.func,
}

ImageSrcSet.defaultProps = {
    className: '',
    alt: 'Beautiful image',
    onLoad: () => {},
}

export default ImageSrcSet;

