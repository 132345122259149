import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Text, Heading } from 'components/common';
import { WhatsappIcon, GmailIcon, BookingDotComIcon } from 'vectors';

const BookingOption = ({Icon, label, href, alignItemsClass, iconClass}) => {
    return (
        <a
            href={href}
            className={`inline-flex items-center text-sm hover:opacity-75 pb-1 px-3 ${alignItemsClass}`}
            target="_blank"
            rel="noreferrer"
        >
            <div className="flex flex-col justify-between">
                <div className={`h-9 ${iconClass}`}>
                    <Icon />
                </div>
                <Heading className="text-2xs text-gray-500 font-bold mt-1" letterSpacing="lg">
                    {label}
                </Heading>
            </div>
        </a>
    )
}

BookingOption.propTypes = {
    Icon: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    alignItemsClass: PropTypes.string,
    iconClass: PropTypes.string,
}

BookingOption.defaultProps = {
    alignItemsClass: 'align-middle',
    iconClass: '',
}

const HostingOptionCard = ({website, url, urlSource, email, whatsapp, children}) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col sm:flex-row items-center px-4 py-5 sm:px-6">
            <div className="min-w-0 w-full sm:w-fit flex-1 sm:flex items-center sm:items-center sm:justify-between">
                <div className="break-all text-center sm:text-left">
                    <div className="text-sm tracking-wide">
                        {children.title}
                    </div>
                    <div className={classNames("mt-1", {
                        'mb-7': !website,
                    })}>
                        <div className="text-center sm:text-left text-sm text-gray-500 text-left">
                            <p>
                                <span className="font-semibold">
                                    {`${t('hospitality.reservationAvailability.reservations')}: `}
                                </span>
                                {children.reservationAvailability}</p>
                        </div>
                    </div>
                    { !!website && (
                         <div className="mt-2">
                            <div className="text-center sm:text-left text-sm text-gray-500 text-left">
                                <p>
                                    <span className="font-semibold">
                                       Website:
                                    </span>
                                    <a
                                        href={website}
                                        className="underline underline-offset-2 font-medium opacity-85 ml-1 text-sm"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {website}
                                    </a>
                                </p>
                            </div>
                        </div>
                    )}
                </div>
                <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                    <div className="flex -space-x-1 overflow-hidden">
                    </div>
                </div>
            </div>
            <div className="mt-2 sm:mt-0 ml-0 sm:ml-5 flex-shrink-0 w-full sm:w-fit">
                <div className="text-sm text-center sm:text-right mr-0 sm:mr-2">
                    <Text className="text-base font-bold opacity-50 text-center">
                        {t('hospitality.howToBook')}
                    </Text>
                </div>
                <div className="mt-1">
                    {!!whatsapp && (
                        <BookingOption
                            Icon={WhatsappIcon}
                            label="Whatsapp"
                            href={`https://api.whatsapp.com/send?phone=${whatsapp}&amp;text=`}
                        />
                    )}

                    {!!email && (
                        <BookingOption
                            Icon={GmailIcon}
                            label="Email"
                            href={`mailto:${email}`}
                            alignItemsClass="content-center"
                            iconClass="pt-0.5"
                        />
                    )}

                    {!!url && (
                        <BookingOption
                            Icon={BookingDotComIcon}
                            label={urlSource}
                            href={url}
                            iconClass="pt-1"
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

HostingOptionCard.propTypes = {
    website: PropTypes.string,
    url: PropTypes.string.isRequired,
    urlSource: PropTypes.string.isRequired,
    email: PropTypes.string,
    whatsapp: PropTypes.string,
    children: PropTypes.shape({
        title: PropTypes.node.isRequired,
        reservationAvailability: PropTypes.node.isRequired,
    }).isRequired,
}

HostingOptionCard.defaultProps = {
    website: '',
    email: '',
    whatsapp: '',
}

export default HostingOptionCard