import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './ImagesCarrousel.css';
import './Loader.css'

const MIN_NUM_IMAGES = 3;
const SECONDS_PER_IMAGE = 3;

const ImagesCarrousel = ({ images, ImageComponent, tick }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransitioningImage, setIsTransitioningImage] = useState(false);
    const [transitioningTo, setTransitioningTo] = useState(null);
    const [seconds, setSeconds] = useState(0)
    const [imagesList, setImagesList] = useState(images.map((img) => { return {base: img.base, sm: img.sm, lg: img.lg, loaded: false} }))

    const transitioningToRef = useRef(transitioningTo)
    transitioningToRef.current = transitioningTo;

    const numLoadedImages = useRef(0)
    const indexLastImage = images.length - 1;

    const imageLoadedHandler = (e) => {
        const url = new URL(e.target.src)
        const imageSrc = url.pathname;

        let loadedImgIndex = null;
        const newImagesList = imagesList.map((img, i) => {
            if ([img.base, img.sm, img.lg].indexOf(imageSrc) !== -1) {
                img.loaded = true
                loadedImgIndex = i
            }
            return img
        })
        setImagesList(newImagesList)

        if (numLoadedImages.current === 0) {
            setCurrentIndex(loadedImgIndex)
        }

        numLoadedImages.current++
    }

    const goToImageByIndex = (imageIndex) => {
        if (imageIndex === currentIndex) {
            return;
        }

        setSeconds(SECONDS_PER_IMAGE)
        setIsTransitioningImage(true)
        setTransitioningTo(imageIndex)
        setTimeout(finishTransition, 560)
    }

    const goToPrevImage = () => {
        if (isTransitioningImage) {
            return
        }
        const prevImageIndex = getPrevLoadedImgIndex()
        goToImageByIndex(prevImageIndex)        
    }

    const goToNextImage = () => {
        if (isTransitioningImage) {
            return
        }
        const nextImageIndex = getNextLoadedImgIndex()
        goToImageByIndex(nextImageIndex)
    }

    const indicatorBtnHandler = (index) => {
        if (!isTransitioningImage && currentIndex !== index) {
            goToImageByIndex(index)
        }
    }

    const finishTransition = () => {
        setCurrentIndex(transitioningToRef.current);
        setIsTransitioningImage(false);
        setTransitioningTo(null);
    }

    const getPrevLoadedImgIndex =  (initial = currentIndex) => {
        const prevImageIndex = initial === 0 ? indexLastImage : initial - 1;
        const isLoaded = imagesList[prevImageIndex].loaded

        return isLoaded ? prevImageIndex : getPrevLoadedImgIndex(prevImageIndex)
    }

    const getNextLoadedImgIndex =  (initial = currentIndex) => {
        const nextImageIndex = initial === indexLastImage ? 0 : initial + 1
        const isLoaded = imagesList[nextImageIndex].loaded

        return isLoaded ? nextImageIndex : getNextLoadedImgIndex(nextImageIndex)
    }
    useEffect(() => {
        if (numLoadedImages.current === 0) {
            return;
        }

        setSeconds(curSeconds => curSeconds - 1)
        if (seconds <= 0) {
            setSeconds(SECONDS_PER_IMAGE)
            goToNextImage();
        }
    }, [tick])

    return (
        <div className="carousel relative lg:mx-auto lg:w-fit lg:max-h-75vh drop-shadow-section brightness-120">
            <div className={classNames('carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-2 sm:mb-4 lg:bottom-1', {
                'invisible': numLoadedImages.current < 2
            })}>
                {imagesList.map((img, i) => {
                        return (
                            <button
                                type="button"
                                className={classNames("carousel-indicators__item", {
                                    'hidden': !img.loaded,
                                    'carousel-indicators__item--active': i === currentIndex,
                                })}
                                key={`indicator_${i}`}
                                onClick={() => indicatorBtnHandler(i)}
                            ></button>
                        )
                    })
                }
            </div>
            <div className={classNames('carousel-inner relative w-full overflow-hidden', {
                'loader' : numLoadedImages.current === 0
            })}>
                {imagesList.map((img, i) => {
                        return (
                            <div
                                key={`img${i}`}
                                className={classNames("carousel__item w-full", {
                                    'carousel__item--active': i === currentIndex,
                                    'carousel__item--transitionfrom': isTransitioningImage && i === currentIndex,
                                    'carousel__item--transitionto': isTransitioningImage && i === transitioningTo
                                })}
                            >
                                    <ImageComponent
                                        base={img.base}
                                        sm={img.sm}
                                        lg={img.lg}
                                        alt={`Brenda e Caue ${i}`}
                                        className="carousel__image block object-fill lg:h-75vh"
                                        onLoad={imageLoadedHandler}
                                    />
                            </div>
                        )})
                    }
            </div>
            <button
                type="button"
                className={classNames('carousel__prevbtn absolute top-0 bottom-0 flex items-center justify-center p-0 text-center text-white border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0', {
                    'invisible': numLoadedImages.current < 2
                })}
                onClick={() => goToPrevImage()}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-9 w-16 sm:h-12 sm:w-20 -ml-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 19l-7-7 7-7"
                    />
                </svg>
                <span className="sr-only">Prev</span>
            </button>
            <button
                type="button"
                className={classNames('carousel__nextbtn absolute top-0 bottom-0 flex items-center justify-center p-0 text-center text-white border-0 hover:outline-none hover:no-underline focus: outline-none focus:no-underline right-0', {
                    'invisible': numLoadedImages.current < 2
                })}
                onClick={() => goToNextImage()}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-9 w-16 sm:h-12 sm:w-20 -ml-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 5l7 7-7 7"
                    />
                </svg>
                <span className="sr-only">Next</span>
            </button>
        </div>
    )
}

const imagesArrayValidator = (props, propName) => {
    const myProp = props[propName];
    if (!myProp) {
        return new Error(`Required ${propName} was not specified`);
    }

    if (!Array.isArray(myProp)) {
        return new Error(`${propName} must be an array`);
    }

    if (myProp.length < MIN_NUM_IMAGES) {
        return new Error(`${propName} must have at least 3 images`);
    }

    if (!myProp.every((image) => image.hasOwnProperty('base') && image.hasOwnProperty('sm') && image.hasOwnProperty('lg'))) {
        return new Error(`${propName} should include items with a base, a small and a large image`);
    }
}

ImagesCarrousel.propTypes = {
    images: imagesArrayValidator,
    ImageComponent: PropTypes.func.isRequired,
    tick: PropTypes.number.isRequired,
}

export default ImagesCarrousel