const MOBILE_BREAKPOINTS = ['xxs', 'xs'];

const breakpoints =  [
    { name: 'xxs', min: 0, max: 480 },
    { name: 'xs', min: 481, max: 768 },
    { name: 'sm', min: 769, max: 1024 },
    { name: 'md', min: 1025, max: 1200 },
    { name: 'lg', min: 1201, max: 1399 },
    { name: 'xl', min: 1400, max: Infinity }
];

const tailwindBreakpoints = {
    xxs:'300px',
    xs: '481px',
    sm: '769px',
    md: '1025px',
    lg: '1201px',
    xl: '1400px',
}

module.exports = {
    MOBILE_BREAKPOINTS,
    breakpoints,
    tailwindBreakpoints,
}