import axios from "axios";
import {API_BASE_PATH} from 'config'

export const saveRSVP = async (rsvpData) => {
    try {
        const config = {
            header: { 
                "Content-Type": "application/json"
            },
        }

        const { data } = await axios.post(`${API_BASE_PATH}rsvp`, rsvpData, config);

        return {
            data: data
        }
    } catch (error) {
        return {
            error: error
        }
    }
}