import { NavLink } from 'react-router-dom'
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Heading } from 'components/common';
import { HomeIcon, GiftIcon, CalendarCheckedIcon, OpenBookIcon } from 'vectors';
import { useBreakpoints } from 'hooks';
import { useScrollPosition } from 'hooks';
import { getLanguageIconByCode } from 'utilities/languages';

const NavItem = ({route, label}) => {
    return (
        <li>
            <NavLink
                to={route}
                className={ ({isActive}) => classNames(
                    'block px-0 py-1 text-gray-700 border border-b-2.5 border-transparent ',
                    {
                        'hover:opacity-60 hover:border-b-gray-500': !isActive,
                        'border-b-main-solid': isActive,
                    }
                )}
                >
                    <Heading className="text-lg text-bold">{label}</Heading>
            </NavLink>
        </li>
    )
}

const MobileNavItem = ({route, Icon, label}) => {
    return (
        <li className="h-full w-full">
            <NavLink
                to={route}
                className={ ({isActive}) => classNames(
                    'block basis-1 grow px-1 py-3.5 text-gray-400',
                    {
                        'hover:opacity-60': !isActive,
                        'text-main-dark': isActive,
                    }
                )}
                >
                    <div className="flex flex-col items-center gap-y-1.5">
                        <div className="h-5 w-5 border-0">
                            <Icon />
                        </div>
                        <div>
                            <Heading className="font-bold text-2xs tracking-wide">{label}</Heading>
                        </div>
                    </div>
            </NavLink>
        </li>
    )
}

const LanguageNavItem = ({Icon, onClick}) => {
    return (
        <li>
            <a
                className="flex flex-row justify-center items-center block px-0 py-1 text-gray-700 border border-b-2.5 border-transparent hover:opacity-60 cursor-pointer w-full -translate-y-3.5 space-x-2"
                onClick={onClick}
                >
                    <div className="rounded-full overflow-hidden h-12 w-12 bg-gray-50 p-1  shadow-t-md drop-shadow-md border">
                        <Icon />
                    </div>
            </a>
        </li>
    )
}

const MobileLanguageNavItem =({Icon, onClick}) => {
    return (
        <li className="h-full w-full">
            <a
                className="relative basis-1 bottom-6 grow p-0 text-gray-400 hover:opacity-60 cursor-pointer"
                onClick={onClick}
            >
                <div className="flex flex-col justify-center items-center">
                    <div className="rounded-full overflow-hidden h-16 w-16 bg-gray-50 p-1.5  shadow-t-md drop-shadow-md border">
                        <Icon />
                    </div>
                </div>
            </a>
        </li>
    )
}

const Nav = ({openLanguageModal}) => {
    const { isMobile } = useBreakpoints();
    const {t, i18n} = useTranslation();
    const NavItemComponent = isMobile ? MobileNavItem : NavItem;
    const LanguageNavComponent = isMobile ? MobileLanguageNavItem : LanguageNavItem;
    const LanguageIcon = getLanguageIconByCode(i18n.language);
    const scrollPosition = useScrollPosition()

    return (
        <nav className={
            classNames("fixed left-0 right-0 sm:shadow-none shadow-t-md z-30 transition-colors duration-500", {
                'sm:mb-6 sm:static bottom-0 bg-gray-50 sm:bg-transparent bottom-0 sm:top-0 sm:mb-6': scrollPosition < 80 || isMobile == true,
                'sm:top-0 pt-8 bg-gray-50': scrollPosition >= 80 && isMobile == false, 
            })
        }>
            <ul className="flex flex-row justify-center h-16 gap-0 sm:gap-4 space-x-0 sm:space-x-4 mt-0 border-0">
                <NavItemComponent route="/" label={t('nav.home')} Icon={HomeIcon}/>
                <NavItemComponent route="/rsvp" label={t('nav.rsvp')} Icon={CalendarCheckedIcon}/>
                <LanguageNavComponent Icon={LanguageIcon} onClick={openLanguageModal} />
                <NavItemComponent route="/gifts" label={t('nav.gifts')} Icon={GiftIcon}/>
                <NavItemComponent route="/instructions" label={'Info'} Icon={OpenBookIcon}/>
            </ul> 
        </nav>
    );
}

export default Nav;

