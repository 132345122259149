import PropTypes from 'prop-types';
const DEFAULT_TAG = 'p'
const DEFAULT_LETTER_SPACING = 'tracking-widest'

const getValidatedTag = (tagName) => {
    const validTags = [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6'
    ];

    return validTags.includes(tagName) ? tagName : DEFAULT_TAG
}

const getLetterSpacingClass = (letterSpacing) => {
    const mapper = {
        xs: 'tracking-tighter',
        sm: 'tracking-tight',
        md: 'tracking-normal',
        lg: 'tracking-wide',
        xl: 'tracking-wider',
        '2xl': 'tracking-widest',
    }

    return mapper[letterSpacing] || DEFAULT_LETTER_SPACING
}
const Heading = ({
    children,
    className,
    tag,
    letterSpacing,
}) => {
    const Tag = getValidatedTag(tag)
    const letterSpacingClass = getLetterSpacingClass(letterSpacing)

    return (
        <Tag className={`font-heading ${letterSpacingClass} ${className}`}>
            {children}
        </Tag>
    )
}

Heading.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    tag: PropTypes.string,
    letterSpacing: PropTypes.string,
}

Heading.defaultProps = {
    className: '',
    tag: DEFAULT_TAG,
    letterSpacing: '2xl',
}

export default Heading