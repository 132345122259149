const es = {
    title: '¡NOS VAMOS A CASAR!',
    subtitle: {
        paragraph1: 'Estamos muy emocionados por empezar esta nueva etapa y queremos compartir nuestra felicidad contigo.',
        paragraph2: 'Con mucho cariño te invitamos a nuestra boda y esperamos contar con tu presencia el día 15 de $t(months.m3) de 2023 a las 4pm en Espaço Abricó.',
    },
    countdown: {
        title: '¡Que Comience la Cuenta Regresiva!',
        days: 'Días',
        hours: 'Horas',
        minutes: 'Minutos',
        seconds: 'Segundos',
    },
    destination: {
        title: 'El Destino',
        subtitle: 'São Sebastião, São Paulo, Brasil',
        paragraph1: 'A pesar de que no vivimos en Brasil, queremos regresar al país donde nos conocimos.',
        paragraph2: 'Escogimos la playa de São Sebastião para celebrar nuestra unión porque nuestro sueño siempre fue casarnos frente al mar, con los pies en la arena y con el atardecer de testigo.',
        paragraph3: 'São Sebastião es un paraíso tropical donde se respira tranquilidad, perfecto para disfrutar del contacto con la naturaleza y el mar. La región cuenta con islas, cascadas y algunas de las mejores playas de la costa de São Paulo.',
    },
    instructions: {
        pageTitle: 'Instrucciones',
        pageSubtitle: {
            paragraph1: 'Queremos que tenga tranquilidad sobre sus opciones de transporte y hospedaje.',
            paragraph2: 'Aquí encontrarás más información sobre cómo llegar y dónde quedar.'
        },
        title: 'Como llegar',
        subtitle: {
            paragraph1: 'Si viajas desde el extrajero, te recomendamos tomar un vuelo a São Paulo, al <style_anchor>Aeropuerto Internacional de Guarulhos (GRU)</style_anchor>.',
            paragraph2: 'Una vez que llegas al aeropuerto, deberás trasladarte hasta São Sebastião, que está ubicado en la costa del estado de São Paulo y a una distancia aproximada de 3 horas.',
            paragraph3: 'Hay varias formas de llegar a São Sebastião desde el Aeropuerto Internacional de Guarulhos (GRU).'
        },
        car: {
            title: 'Rentar un carro',
            paragraph1: ' En el aeropuerto puedes rentar un auto y manejar hasta São Sebastião, te recomendamos que lleves efectivo para pagar los peajes.',
            paragraph2: 'Si sales desde el aeropuerto, continua por la carretera SP-070 (Trabalhadores/Ayrton Senna) y maneja en dirección a São José dos Campos. Luego toma la carretera SP-099 (Tamoios) a Caraguatatuba. Y luego continúa por BR-101 (Rio-Santos) y llegarás a São Sebastião.',
            mapsLink: 'Mira el trayecto en Google Maps',
        },
        other: {
            title: 'Otras formas',
            bus: {
                title: 'En Bus',
                paragraph1: ' Para tomar un bus desde el Aeropuerto Internacional de Guarulhos (GRU) debes ir hasta el Terminal 2 este. Ahí podrás comprar pasaje de ida y vuelta en la empresa Pássaro Marron con destino a São Sebastião litoral Norte.',
                paragraph2: 'Para ver horarios y precios entra en el website:',
                paragraph3: 'Si no te encuentras en el aeropuerto y planeas tomar transporte público, te recomendamos ir hasta la parada de autobuses Rodoviária do Tietê. Ahí también podrás adquirir pasajes de ida y vuelta en bus.',
                paragraph4: 'Puedes ver los horarios y precios aquí:',
            },
            uber: {
               title: 'En Uber / Lyft' ,
               paragraph1: 'Las aplicacciones móviles de Uber y Lyft funcionan en Brasil y te pueden llevar directo hasta São Sebastião.'
            },
            taxi: {
                title: 'En Taxi',
                paragraph1: ' El aeropuerto GRU te ofrece servicios de taxi en todos los terminales. Puedes buscar el punto de taxi mas cercano de tu terminal de llegada o llamar al número abajo para más informaciones:'
            }
        }
    },
    hospitality: {
        title: 'Hospedaje',
        airbnb: {
            tab: 'AIRBNB',
            title: 'Un hospedaje económico puede ser un AirBnb cerca al lugar donde se celebrará la boda',
            link: 'Mira las opciones en AirBnb cerca donde se celebrará la boda',
        },
        hotels:{
            tab: 'HOTELES',
            title: 'Opciones de hoteles cerca al lugar donde se celebrará la boda',
        },
        inns:{
            tab: 'POSADAS',
            title: 'Opciones de posadas cerca al lugar donde se celebrará la boda',
        },
        hostels:{
            tab: 'HOSTELS',
            title: 'Opciones de hostels cerca al lugar donde se celebrará la boda',
        },
        reservationAvailability: {
            reservations: 'Reservaciones',
            available: 'ya disponibles',
            notAvailable: 'disponibles a partir de {{month}} de {{year}}'
        },
        howToBook: 'Como reservar',
        actions: {
            sendEmail: 'Enviar Email',
        }
    },
    months: {
        m0: 'enero',
        m1: 'febrero',
        m2: 'marzo',
        m3: 'abril',
        m4: 'mayo',
        m5: 'junio',
        m6: 'julio',
        m7: 'agosto',
        m8: 'septiembre',
        m9: 'octubre',
        m10: 'noviembre',
        m11: 'deciembre'
    },
    footer: {
        title: '¿Dudas?',
        subtitle: 'Estamos aqui:',
    },
    rsvp: {
        form: {
            title: 'CONFIRMA TU PRESENCIA AQUÍ',
            nameLabel: 'Nombre',
            numberInviteesLabel: 'Número de acompañantes',
            numberInviteesPlaceholder: 'Selecciona una opción',
            submit: 'Confirmar'
        },
        success: 'Gracias por confirmar su presencia!',
        errors: {
            generic: 'Error. Por favor, intenta de nuevo.',
            unique: 'Ya existe una confirmación con tu nombre!',
            required: 'Necesario llenar el campo',
            formRequired: 'Por favor coloca tu nombre y el número de acompañantes',
        }
    },
    gifts: {
        title: 'Regalos',
        subtitle: {
            paragraph1: '¡Tu presencia en nuestra boda es lo más importante para nosotros!',
            paragraph2: 'En caso de querer hacernos un regalo, como no vivimos en Brasil llevar los obsequios a casa nos costaría demasiado por el excesso de peso en la maleta.',
            paragraph3: 'Así que preferimos que los regalos que deseen darnos sean en efectivo.',
        },
        howToTitle: 'Como enviar su regalo',
        howToSubtitle: 'Selecciona el país donde vives',
        options: {
            title: 'Opciones',
            cash: {
                title: 'En efectivo',
                content: 'Puedes llevar tu regalo a la fiesta.',
            },
            pix: {
                title: 'PIX',
                content: 'Llave PIX',
            },
            transferSoles: {
                title: 'Transferencia en Soles',
                content: 'Número de cuenta',
            },
            transferDollars: {
                title: 'Transferencia en dólares',
                content: 'Número de cuenta',
            },
            zelle: {
                title: 'Zelle',
                content: 'Número telefónico',
            }
        },
        bank: 'Banco',
    },
    countries: {
        'br': 'Brasil',
        'pe': 'Perú',
        'us': 'EUA',
        'others': 'Otros',
    },
    nav: {
        home: 'Home',
        rsvp: 'RSVP',
        gifts: 'Regalos',
        language: 'Idioma'
    }
}
export default es;
