const en = {
    title: 'WE ARE GETTING MARRIED!',
    subtitle: {
        paragraph1: 'We are excited to take the next step in our lives and we want to share our happiness with you.',
        paragraph2: 'With love, we invite you to our wedding and we count on your presence on $t(months.m3) 15, 2023 at 4PM in Espaço Abricó.',
    },
    countdown: {
        title: 'Let the Countdown begin!',
        days: 'Days',
        hours: 'Hours',
        minutes: 'Minutes',
        seconds: 'Seconds',
    },
    destination: {
        title: 'The Destination',
        subtitle: 'São Sebastião, São Paulo, Brazil',
        paragraph1: 'Even though we don\'t live in Brazil, we have decided to celebrate our wedding in the country we met.',
        paragraph2: 'We chose São Sebastião to celebrate our union as it was our dream to get married facing the ocean, at the beach, and with the sunset as our witness.',
        paragraph3: 'São Sebastião is a tropical paradise where you can breath tranquility, perfect to enjoy the contact with nature. The region counts with islands, waterfalls and some of the most beautiful islands in the São Paulo\'s coast.',
    },
    instructions: {
        pageTitle: 'Instructions',
        pageSubtitle: {
            paragraph1: 'We want you to have peace of mind about your transportation and lodging options.',
            paragraph2: 'Here you will find more information on how to get there and where to stay.'
        },
        title: 'How to get there',
        subtitle: {
            paragraph1: 'If you are traveling from abroad, we recommend that you flight to <style_anchor>Guarulhos International Airport (GRU)</style_anchor> in São Paulo.',
            paragraph2: 'Once you arrive, you should head to São Sebastião, located in the São Paulo State\'s coast around 3 hours away.',
            paragraph3: 'There are two ways to get to São Sebastião from the Guarulhos International Airport (GRU).'
        },
        car: {
            title: 'Rent a car',
            paragraph1: 'You can rent a car at the airport and drive to São Sebastião. We recommend that you bring cash to pay for the tolls.',
            paragraph2: 'Leaving the airport you should go to the SP-070 Highway (Trabalhadores/Ayrton Senna) and drive in the São José dos Campos direction. Then, you go to through the SP-099 Highway (Tamoios) in direction to Caraguatatuba. Finally, you finish your trip by going through the BR-101 Highway (Rio-Santos) until you arrive to São Sebastião.',
            mapsLink: 'See the trip details in Google Maps',
        },
        other: {
            title: 'Other options',
            bus: {
                title: 'Bus',
                paragraph1: 'To get a bus you should go the Guarulhos Internal Airport\'s Terminal 2 East. There you can buy your two-way ticket to São Sebastião with the provider called Pássaro Marrom.',
                paragraph2: 'Check the times and prices in their website:',
                paragraph3: 'If you plan to go by bus but you are not in the airport, we recommend that you head to the Tietê Bus Station. There you can find buses that offer two-way tickets to São Sebastião.',
                paragraph4: 'You can check the times and prices in the website:',
            },
            uber: {
                title: 'Uber / Lyft' ,
                paragraph1: 'The Uber and Lyft services work great in Brazil and can take you all the way to São Sebastião.'
             },
             taxi: {
                 title: 'Taxi',
                 paragraph1: 'The GRU airport offers taxi services in all terminals. You can find the closest taxi point in your arriving terminal or call the number below for more information:'
             }
        }
    },
    hospitality: {
        title: 'Lodging',
        airbnb: {
            tab: 'AIRBNB',
            title: 'An economic lodging option would be an AirBnb close to the ceremony location.',
            link: 'Check the Airbnb options close to the wedding venue.',
        },
        hotels:{
            tab: 'HOTELS',
            title: 'Check the hotels close to the wedding venue',
        },
        inns:{
            tab: 'INNS',
            title: 'Check the inns close to the wedding venue',
        },
        hostels:{
            tab: 'HOSTELS',
            title: 'Check the hostels close to the wedding venue',
        },
        reservationAvailability: {
            reservations: 'Reservations ',
            available: 'already available',
            notAvailable: 'will be available on {{month}}, {{year}}'
        },
        howToBook: 'How to book',
        actions: {
            sendEmail: 'Send Email',
        }
    },
    months: {
        m0: 'January',
        m1: 'February',
        m2: 'March',
        m3: 'April',
        m4: 'May',
        m5: 'June',
        m6: 'July',
        m7: 'August',
        m8: 'September',
        m9: 'October',
        m10: 'November',
        m11: 'December'
    },
    footer: {
        title: 'Do you have questions?',
        subtitle: 'We are here:',
    },
    rsvp: {
        form: {
            title: 'CONFIRM YOUR PRESENCE',
            nameLabel: 'Name',
            numberInviteesLabel: 'Number of people attending',
            numberInviteesPlaceholder: 'Choose an option',
            submit: 'Confirm'
        },
        success: 'Thanks for confirming your presence!',
        errors: {
            generic: 'Error. Please try again',
            unique: 'There is an RSVP confirmation with your name already!',
            required: 'Required',
            formRequired: 'Please fill out your name and the number of people attending',
        }
    },
    gifts: {
        title: 'Gifts',
        subtitle: {
            paragraph1: 'Your presence at our wedding is the greatest gift of all!',
            paragraph2: "If you wish to honor us with a gift, since we don't live in Brazil, bringing the gift back home would be too expensive due to the airport luggage fees.",
            paragraph3: "For that reason, we would prefer to have the gifts delivered in cash.",
        },
        howToTitle: 'Ways to deliver your gift',
        howToSubtitle: 'Select which country do you live in',
        options: {
            title: 'Options',
            cash: {
                title: 'Cash',
                content: "You can handle it to us at the ceremony",
            },
            pix: {
                title: 'PIX',
                content: 'PIX Key',
            },
            transferSoles: {
                title: 'Transfer in Soles (PEN)',
                content: 'Account number',
            },
            transferDollars: {
                title: 'Transfer in Dollars',
                content: 'Account number',
            },
            zelle: {
                title: 'Zelle',
                content: 'Phone number',
            }
        },
        bank: 'Bank',
    },
    countries: {
        'br': 'Brazil',
        'pe': 'Peru',
        'us': 'US',
        'others': 'Others',
    },
    nav: {
        home: 'Home',
        rsvp: 'RSVP',
        gifts: 'Gifts',
        language: 'Language'
    }
}
export default en;