import PropTypes from 'prop-types';
import { SuccessIcon, ErrorIcon, WarningIcon } from "vectors";

const getColorAndIconByType = (type) => {
    switch (type) {
        case 'success':
            return {
                Icon: SuccessIcon,
                bgClasses: 'border-green-400 bg-green-50',
                colorClasses: 'text-green-700 hover:text-green-600'
            }
        case 'error':
            return {
                Icon: ErrorIcon,
                bgClasses: 'border-red-400 bg-red-50',
                colorClasses: 'text-red-700 hover:text-red-600'
            }
        case 'warning':
        default:
            return {
                Icon: WarningIcon,
                bgClasses: 'border-yellow-400 bg-yellow-50',
                colorClasses: 'text-yellow-700 hover:text-yellow-600'
            }
    }
}

const Alert = ({type, children, withIcon, className}) => {
    const { Icon, bgClasses, colorClasses } = getColorAndIconByType(type);

    return (
        <div className={`border-l-4 p-4 pr-6 ${bgClasses} ${className}`}>
            <div className="flex items-center">
                {withIcon && (
                    <>
                        <div className="flex-shrink-0 pb-0.5">
                            <Icon
                                className={`h-5 w-5 ${colorClasses}`}
                                ariaHidden="true"
                            />
                        </div>
                    </>
                )}
                <div className="ml-3">
                    <p className={`text-sm ${colorClasses}`}>
                        {children}
                    </p>
                </div>
            </div>
        </div>
    )
}

Alert.propTypes = {
    type: PropTypes.oneOf(['success', 'error', 'warning']).isRequired,
    children: PropTypes.node.isRequired,
    withIcon: PropTypes.bool,
    className: PropTypes.string,
}

Alert.defaultProps = {
    withIcon: true,
    className: '',
}

export default Alert