import PropTypes from 'prop-types';
const DEFAULT_TAG = 'p'

const getValidatedTag = (tagName) => {
    const validTags = [
        'p',
        'span'
    ];

    return validTags.includes(tagName) ? tagName : DEFAULT_TAG
}

const Text = ({
    children,
    className,
    tag,
}) => {
    const Tag = getValidatedTag(tag)
    return (
        <Tag className={`font-body ${className}`}>
            {children}
        </Tag>
    )
}

Text.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    tag: PropTypes.string,
}

Text.defaultProps = {
    className: '',
    tag: DEFAULT_TAG,
}

export default Text