const HomeIcon = () => {
    return (
        //Solid MUI CORE
        // <svg
        //     xmlns="http://www.w3.org/2000/svg"
        //     width="100%"
        //     height="100%"
        //     viewBox="0 0 24 24" 
        // >
        //     <g fill="#444" transform="scale(0.0234375 0.0234375)">
        //         <path d="M426 854h-212v-342h-128l426-384 426 384h-128v342h-212v-256h-172v256z"/>
        //     </g>
        // </svg>


        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 512 512" 
        >
            <path fill="currentColor"
                d="M509.8 227.5L448 177.8v-76c0-3.3-2.7-6-6-6h-20c-3.3 0-6 2.7-6 6v50.1L276.1 39.1c-11.7-9.5-28.5-9.5-40.2 0L2.2 227.5c-2.6 2.1-3 5.9-.9 8.4l12.6 15.6c2.1 2.6 5.9 3 8.5.9L64 218.9v229c0 17.7 14.3 32 32 32h116c6.6 0 12-5.4 12-12V335.8l64 .3v132.2c0 6.6 5.4 12 12 12l116-.3c17.7 0 32-14.3 32-32V219l41.6 33.5c2.6 2.1 6.4 1.7 8.5-.9l12.6-15.6c2.1-2.6 1.6-6.4-.9-8.5zM416 448l-96 .3V316c0-6.6-5.4-12-12-12l-104-.3c-6.6 0-12 5.4-12 12V448H96V193.1l156.2-126c2.2-1.8 5.3-1.8 7.5 0l156.2 126V448z"
            />
        </svg>
    )
}

export default HomeIcon