
import { Fragment, useState, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Listbox, Transition } from '@headlessui/react'

import { Heading, Text, Alert } from "components/common"
import { saveRSVP } from 'api/rsvp';

const SUCCESS_REQUEST_MSG = 'rsvp.success';
const FAILED_REQUEST_MSGS = {
    GENERIC: {
        isError: true,
        msg:'rsvp.errors.generic',
    },
    unique: {
        isError: false,
        msg:'rsvp.errors.unique',
    },
    required: {
        isError: true,
        msg:'rsvp.errors.formRequired',
    },
}

const numberInviteesOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
const initialState = {
    name: {
        value: '',
        touched: false,
        hasError: true,
        error: ''
    },
    numberInvitees: {
        value: '',
        touched: false,
        hasError: true,
        error: ''
    },
    isFormValid: false,
}

const Rsvp = () => {
    const { t } = useTranslation();
    const [showResponseResult, setShowResponseResult] = useState(false);
    const [isRequestError, setIsRequestError] = useState(false);
    const [resultResponseMessage, setResultResponseMessage] = useState('');

    const onInputChange = (name, value) => {
        const {hasError, error} = validateInput(name, value);
        let isFormValid = true;

        for (const key in formState) {
            const item = formState[key];

            if (key === name && hasError) {
                isFormValid = false;
                break;
            } else if (key !== name && item.hasError) {
                isFormValid = false;
            }
        }
        dispatch({
            type: 'UPDATE_FORM',
            data: {
                name,
                value,
                hasError,
                error,
                touched: false,
                isFormValid
            }
        })
    }

    const onFocusOut = (name, value) => {
        const { hasError, error } = validateInput(name, value)
        let isFormValid = true

        for (const key in formState) {
          const item = formState[key]
          if (key === name && hasError) {
            isFormValid = false
            break
          } else if (key !== name && item.hasError) {
            isFormValid = false
            break
          }
        }
        dispatch({
            type: 'UPDATE_FORM',
            data: {
                name,
                value,
                hasError,
                error,
                touched: true,
                isFormValid
            }
        })
    }

    const formsReducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FORM':
                const { name, value, hasError, error, touched, isFormValid } = action.data;
                return {
                    ...state,
                    [name]: {
                        ...state[name],
                        value,
                        hasError,
                        error,
                        touched
                    },
                    isFormValid
                }

            default: 
                return state
        }
    }
    const [formState, dispatch] = useReducer(formsReducer, initialState)

    const validateInput = (name, value) => {
        let hasError = false;
        let error = '';

        switch (name) {
            case 'name':
                if (value.trim() === '') {
                    hasError = true;
                    error = t('rsvp.errors.required');
                } else {
                    hasError = false;
                    error = '';
                }
                break;
            case 'numberInvitees':
                if (value.trim() === '') {
                    hasError = true;
                    error = t('rsvp.errors.required');
                } else {
                    hasError = false;
                    error = '';
                }
                break;
            default: 
        }
        return {hasError, error}
    }

    const handleSubmit = async  e => {
        e.preventDefault();
        let isFormValid = true;

        for (const name in formState) {
            const item = formState[name]
            const { value } = item
            const { hasError, error } = validateInput(name, value)
            if (hasError) {
              isFormValid = false
            }
            if (name) {
              dispatch({
                type: 'UPDATE_FORM',
                data: {
                  name,
                  value,
                  hasError,
                  error,
                  touched: true,
                  isFormValid,
                },
              })
            }
        }
        if (!formState.isFormValid) {
            return;
        }

        setIsRequestError(false)
        setShowResponseResult(false)
        setResultResponseMessage('')

        await saveRSVP({
            name: formState.name.value,
            numberInvitees: formState.numberInvitees.value
        }).then(r => {
            const data = r.error ? r.error.response.data : r.data;
            const isSuccess = data.success || false;
            const error = (data && data.error && data.error.errors && data.error.errors.name) ? data.error.errors.name.kind : 'GENERIC'; 
            setIsRequestError(!isSuccess && FAILED_REQUEST_MSGS[error].isError);
            setResultResponseMessage(t(isSuccess ? SUCCESS_REQUEST_MSG : FAILED_REQUEST_MSGS[error].msg));
        }).catch(error => {
            console.log(error);
            setIsRequestError(true);
        }).finally(() => setShowResponseResult(true))
    }

    return (
        <div className="flex flex-col space-between">
            <div className="basis-2/6 flex flex-col px-12 mt-8 mx-auto max-w-70vh md:max-w-80vh lg:max-w-80vh">
                <Heading
                    className="text-3xl sm:text-4xl md:text-5xl text-center"
                    tag="h2"
                >
                    <div className="border-b-4 border-main-solid ">
                        <span className="pb-2">
                            RSVP
                        </span>
                    </div>
                </Heading>
            </div>
            <div className="relative mx-auto max-w-2xl mb-24">
                <div className="bg-white rounded-md shadow-lg mt-10 w-auto sm:w-50vh">
                    {showResponseResult && (
                        <Alert type={isRequestError ? 'error' : 'success'}>
                            <Text className="text-lg">{resultResponseMessage}</Text>
                        </Alert>
                    )}

                    <div className="pt-4 pb-16 px-12 sm:px-6 lg:px-8 lg:pb-14">
                        <Text className="text-center mb-8 leading-8 text-lg sm:text-2xl opacity-75 pt-6">
                            {t('rsvp.form.title')}
                        </Text>
                        <form
                            autoComplete="on"
                            onSubmit={handleSubmit}
                        >
                            <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                    {t('rsvp.form.nameLabel')}
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="fullname"
                                        id="name"
                                        autoComplete="name"
                                        value={formState.name.value}
                                        className={classNames(
                                            "block w-full rounded-md border-gray-300 py-3 px-4 shadow-md border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
                                            {'border border-red-500': formState.name.touched && formState.name.hasError}
                                        )}
                                        onChange={e => {
                                            onInputChange("name", e.target.value)
                                        }}
                                        onBlur={e => {
                                            onFocusOut("name", e.target.value)
                                        }}
                                    />
                                    {formState.name.touched && formState.name.hasError && (
                                        <p className="mt-2 text-sm text-red-600" id="email-error">
                                            {formState.name.error}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="my-8">
                                <Listbox
                                    name="number_invitees"
                                    defaultValue = 'Cuantos invitados'
                                    value={formState.numberInvitees.value}
                                    onChange={(value)=> {onInputChange('numberInvitees', value)}}
                                    className="overflow-auto"
                                >
                                    {({ open }) => (
                                        <>
                                        <Listbox.Label className="block text-sm font-medium text-gray-700">
                                            {t('rsvp.form.numberInviteesLabel')}
                                        </Listbox.Label>
                                            <div className="relative mt-1">
                                                <Listbox.Button className={classNames(
                                                    "relative w-full cursor-pointer rounded-md shadow-md border border-gray-300 bg-white py-3.5 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm overflow-visible",
                                                    {'border border-red-500': formState.numberInvitees.touched && formState.numberInvitees.hasError}
                                                )}>
                                                    <span className="block truncate">{formState.numberInvitees.value || t('rsvp.form.numberInviteesPlaceholder')}</span>
                                                </Listbox.Button>
                                                { formState.numberInvitees.touched && formState.numberInvitees.hasError && (
                                                    <p className="mt-2 text-sm text-red-600" id="email-error">
                                                        {formState.numberInvitees.error}
                                                    </p>
                                                )}

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {numberInviteesOptions.map((num) => (
                                                            <Listbox.Option
                                                                key={num}
                                                                className={({ active }) =>
                                                                    classNames(
                                                                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                    )}
                                                                value={num}
                                                            >
                                                            <span className={classNames(formState.numberInvitees.value ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                {num}
                                                            </span>
                                                        </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </>
                                    )}
                                </Listbox>
                            </div>
                            <div className="sm:col-span-2">
                                <button
                                    type="submit"
                                    className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    {t('rsvp.form.submit')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rsvp