const hostingOptions = {
    hotels: [
        {
          title: 'Hotel Veleiro',
          availableAt: {
              month: 1,
              year: 2023,
          },
          website: 'https://www.hotelveleiro.com/',
          url: 'https://www.booking.com/Share-ALQwAN',
          urlSource: 'Booking.com',
          email: 'reserva@hotelveleiro.com',
          whatsapp: '551238923620',
        },
        {
          title: 'Hotel Arrastão',
          availableAt: {
              month: 1,
              year: 2023,
          },
          website: 'http://www.hotelarrastao.tur.br/',
          url: 'https://www.booking.com/Share-o5Ltevv',
          urlSource: 'Booking.com',
          email: '',
          whatsapp: '5512981388496',
        },
        {
          title: 'Hotel Guarda Mor',
          availableAt: {
              month: 1,
              year: 2023,
          },
          website: 'https://hotelguardamor.com.br/',
          url: 'https://www.booking.com/Share-54bfZaD',
          urlSource: 'Booking.com',
          email: 'reserva@hotelguardamor.com.br',
          whatsapp: '',
        },
        {
          title: 'Hotel Recanto dos Pássaros',
          availableAt: {
              month: 1,
              year: 2023,
          },
          website: 'http://www.recantodospassaros.tur.br/',
          url: 'https://www.booking.com/Share-bT56mU0',
          urlSource: 'Booking.com',
          email: 'recantodospassaros@terra.com.br',
          whatsapp: '5512991159863',
        },
        {
          title: 'Hotel Roma',
          availableAt: {
              month: 1,
              year: 2023,
          },
          website: '',
          url: 'https://www.booking.com/Share-fEjHaM',
          urlSource: 'Booking.com',
          email: '',
          whatsapp: '',
        },
    ],
    inns: [
        {
            title: 'Pousada Ana Doce',
            availableAt: {
                month: 10,
                year: 2022,
            },
            website: 'https://pousadaanadoce.com.br/ ',
            url: 'https://www.booking.com/Share-CNNDjH',
            urlSource: 'Booking.com',
            email: '',
            whatsapp: '5512982702149',
        },
        {
            title: 'Pousada Shiva',
            availableAt: {
                month: 10,
                year: 2022,
            },
            website: 'https://casa-da-tica.negocio.site/',
            url: 'https://www.booking.com/Share-fEjHaM',
            urlSource: 'Booking.com',
            email: '',
            whatsapp: '5512981341222',
        },
        {
            title: 'Pousada Kaya',
            availableAt: {
                month: 10,
                year: 2022,
            },
            website: '',
            url: 'https://www.booking.com/Share-46B0xU',
            urlSource: 'Booking.com',
            email: '',
            whatsapp: '5512997141431',
        },
        {
            title: 'Pousada da Banda',
            availableAt: {
                month: 10,
                year: 2022,
            },
            website: 'https://pousadadabanda.com.br/',
            url: 'https://www.booking.com/Share-CmF4K5',
            urlSource: 'Booking.com',
            email: '',
            whatsapp: '5512982150463',
        },
        {
            title: 'Pousada Ipê São Sebastião',
            availableAt: {
                month: 10,
                year: 2022,
            },
            website: 'https://pousadaipesaosebastiao.com.br/',
            url: 'https://www.booking.com/Share-WyIVUv',
            urlSource: 'Booking.com',
            email: '',
            whatsapp: '551238931698',
        },
        {
            title: 'Pousada da Sesmaria',
            availableAt: {
                month: 10,
                year: 2022,
            },
            website: 'https://www.pousadadasesmaria.com.br/',
            url: 'https://www.booking.com/Share-bAD7GE',
            urlSource: 'Booking.com',
            email: '',
            whatsapp: '5512996692347',
        },
    ],
    hostels: [
      {
          title: "Recanto Espelho D'agua",
          availableAt: {
              month: 10,
              year: 2022,
          },
          website: '',
          url: 'https://www.hotels.com/es/ho1769110496/recanto-espelho-d-agua-sao-sebastiao-brasil/',
          urlSource: 'Hotels.com',
          email: '',
          whatsapp: '',
      },
      {
          title: "Pousada ao Mare",
          availableAt: {
              month: 10,
              year: 2022,
          },
          website: '',
          url: 'https://www.hotels.com/es/ho411138/pousada-porto-mare-sao-sebastiao-brasil/',
          urlSource: 'Hotels.com',
          email: '',
          whatsapp: '',
      },
  ]
}

export default hostingOptions;
