import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';

const Countdown = ({ dateISOString, tick }) => {
    const [isValidDate, setIsValidDate] = useState(false)
    const [days, setDays] = useState(0)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)
    const MAX_SECONDS = 59;
    const MAX_MINUTES = 59;
    const MAX_HOURS = 23;
    const MIN_IN_SECONDS = 24;
    const HOUR_IN_MIN = 24;
    const DAY_IN_HOURS = 24;
    const DAY_IN_MILISSECONDS = 86400000

    const onSecondPassed = () => {
        if (!isValidDate) {
            return;
        }

        const newSeconds = seconds - 1;
        if (newSeconds >= 0) {
            setSeconds(newSeconds)
            return 
        }

        setSeconds(MAX_SECONDS);

        const newMinutes = minutes - 1;
        if (newMinutes >= 0) {
            setMinutes(newMinutes)
            return
        }

        setMinutes(MAX_MINUTES)

        const newHours = hours - 1;
        if (newHours >= 0) {
            setHours(newHours)
            return
        }

        setHours(MAX_HOURS)

        const newDays = days - 1;
        if (newDays >= 0) {
            setDays(newDays)
        }
    }

    const getDateDiffObj = (timeInMs) => {
        let days = timeInMs / DAY_IN_MILISSECONDS
        let hours = (days % 1) * DAY_IN_HOURS
        let minutes = (hours % 1) * HOUR_IN_MIN
        let seconds = (minutes % 1) * MIN_IN_SECONDS

        return {
            days: Math.floor(days),
            hours: Math.floor(hours),
            minutes: Math.floor(minutes),
            seconds: Math.floor(seconds), 
        }
    }

    const setInitialValues = () => {
        const date = new Date(dateISOString);
        if (date.toString() === 'Invalid Date') {
            return
        }

        const now = new Date();
        const msToDate = date.getTime() - now.getTime();

        const {days, hours, minutes, seconds} = getDateDiffObj(msToDate)

        setDays(days)
        setHours(hours)
        setMinutes(minutes)
        setSeconds(seconds)
        setIsValidDate(true)
    }

    useEffect(() => {
        setInitialValues()
    }, [])

    useEffect(()=> {
        // console.log('tick prop changed')
        isValidDate && onSecondPassed()
    }, [tick])

    const CountdownItem = ({dateEl, label}) => (
        <div className="flex flex-col justify-center mb-6 sm:mb-0">
            <div className="text-4xl lg:text-5xl mb-0 sm:mb-1 font-heading">{dateEl}</div>
            <div className="text-lg text-gray-500 font-body tracking-widest">{label}</div>
        </div>
    )
    return (
        <div className="flex flex-col xs:flex-row justify-evenly align-center ">
            <CountdownItem
                dateEl={days}
                label={t('countdown.days')}
            />
            <CountdownItem
                dateEl={hours}
                label={t('countdown.hours')}
            />
            <CountdownItem
                dateEl={minutes}
                label={t('countdown.minutes')}
            />
            <CountdownItem
                dateEl={seconds}
                label={t('countdown.seconds')}
            />
        </div>
    )
}


Countdown.propTypes = {
    dateISOString: PropTypes.string.isRequired,
    tick: PropTypes.number.isRequired,
}

export default Countdown