import { useState, useEffect } from "react";
import { useWindowDimensions } from "hooks";
import { breakpoints, MOBILE_BREAKPOINTS } from 'config/breakpoints'

const getCurrentBreakpointName = (width = 0) => {
    const breakpoint = breakpoints.filter(bkpoint => width >= bkpoint.min && width <= bkpoint.max);
    return breakpoint.length ? breakpoint[0].name : null;
}

const getIsMobile = (breakpointName) => {
    return MOBILE_BREAKPOINTS.includes(breakpointName)
}

const useBreakpoints = () => {
    const [breakpointName, setBreakpointName] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const { width } = useWindowDimensions();

    useEffect(() => {
        const currentBreakpointName = getCurrentBreakpointName(width);
        setBreakpointName(currentBreakpointName);
    }, [width])

    useEffect(() => {
        const isMobileSize = getIsMobile(breakpointName);
        setIsMobile(isMobileSize);
    }, [breakpointName]);

    return {
        isMobile,
        breakpointName,
    }
}

export default useBreakpoints;