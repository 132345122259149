import { Fragment } from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dialog, Transition } from '@headlessui/react'

import { Heading } from 'components/common';
import { FlagBrazil, FlagPeru, FlagUSA, XMarkIcon } from 'vectors'


const languages = [
    {
        name: 'Português',
        code: 'pt',
        Flag: FlagBrazil,
    },
    {
        name: 'Español',
        code: 'es',
        Flag: FlagPeru,
    },
    {
        code: 'en',
        name: 'English',
        Flag: FlagUSA,
    }
];
const LanguageSelectorModal = ({ show, currentLanguage, onLanguageChange, close }) => {
    const handleLanguageSelected = (lang) => {
        onLanguageChange(lang)
    }

    const handleCloseModal = () => {
        if (!currentLanguage) {
            return;
        }

        close();
    }

    return (
        <Transition.Root
            as={Fragment}
            show={show}
            appear
        >
            <Dialog
                as="div"
                className="relative z-40"
                onClose={handleCloseModal}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity z-9" aria-hidden="true" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 overflow-y-auto p-4 pt-12 sm:pt-24 md:p-20">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="mx-auto max-w-2xl px-6 py-12 transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all text-center">
                            {currentLanguage && (
                                <div className="absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                        onClick={handleCloseModal}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                            )}
                            <Dialog.Title as={Fragment}>
                                <Heading
                                    className="text-2xl xs:text-3xl mb-4 pb-4"
                                    tag="h2"
                                >
                                    Idioma / Language
                                </Heading>
                            </Dialog.Title>
                            <div className="flex flex-col sm:flex-row flex-nowrap items-center justify-around pt-2">
                                {languages.map(language => (
                                    <button
                                        key={language.code}
                                        type="button"
                                        className={classNames(
                                            "flex flex-col items-center justify-center text-gray-500 mb-6 sm:mb-0 rounded-lg h-28 w-28 focus:outline-none",
                                            {
                                                'text-blue-600 font-bold': language.code === currentLanguage,
                                            }
                                        )}
                                        onClick={() => handleLanguageSelected(language.code)}
                                    >
                                        <div className={classNames(
                                                "rounded-full overflow-hidden h-16 w-16 border-3",
                                                {
                                                    'border-white': language.code !== currentLanguage,
                                                    'border-blue-600': language.code === currentLanguage
                                                }
                                            )}
                                        >
                                            <language.Flag />
                                        </div>
                                        <Heading className="text-sm font-bold pt-3">
                                            {language.name}
                                        </Heading>
                                    </button>    
                                ))}
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

LanguageSelectorModal.propTypes = {
    currentLanguage: PropTypes.string,
    onLanguageChange: PropTypes.func.isRequired,
    show: PropTypes.bool,
    close: PropTypes.func,
}

LanguageSelectorModal.defaultProps = {
    currentLanguage: null,
    show: false,
    close: () => {}
}

export default LanguageSelectorModal;