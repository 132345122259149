import img1 from 'assets/images/carrousel_1.JPG';
import img1Sm from 'assets/images/carrousel_1_sm.jpeg';
import img1Xs from 'assets/images/carrousel_1_xs.jpeg';

import img2 from 'assets/images/carrousel_2.JPG';
import img2Sm from 'assets/images/carrousel_2_sm.jpeg';
import img2Xs from 'assets/images/carrousel_2_xs.jpeg';

import img3 from 'assets/images/carrousel_3.JPG';
import img3Sm from 'assets/images/carrousel_3_sm.jpeg';
import img3Xs from 'assets/images/carrousel_3_xs.jpeg';

import img4 from 'assets/images/carrousel_4.JPEG';
import img4Sm from 'assets/images/carrousel_4_sm.jpeg';
import img4Xs from 'assets/images/carrousel_4_xs.jpeg';

import img5 from 'assets/images/carrousel_5.JPG';
import img5Sm from 'assets/images/carrousel_5_sm.jpeg';
import img5Xs from 'assets/images/carrousel_5_xs.jpeg';

import img6 from 'assets/images/carrousel_6.JPG';
import img6Sm from 'assets/images/carrousel_6_sm.jpeg';
import img6Xs from 'assets/images/carrousel_6_xs.jpeg';

import img7 from 'assets/images/carrousel_7.JPG';
import img7Sm from 'assets/images/carrousel_7_sm.jpeg';
import img7Xs from 'assets/images/carrousel_7_xs.jpeg';

import img8 from 'assets/images/carrousel_8.JPG';
import img8Sm from 'assets/images/carrousel_8_sm.jpeg';
import img8Xs from 'assets/images/carrousel_8_xs.jpeg';

import img9 from 'assets/images/carrousel_9.JPG';
import img9Sm from 'assets/images/carrousel_9_sm.jpeg';
import img9Xs from 'assets/images/carrousel_9_xs.jpeg';

import img10 from 'assets/images/carrousel_10.JPEG';
import img10Sm from 'assets/images/carrousel_10_sm.jpeg';
import img10Xs from 'assets/images/carrousel_10_xs.jpeg';

const carouselImages = [
    {
        base: img1Xs,
        sm: img1Sm,
        lg: img1,
    },
    {
        base: img2Xs,
        sm: img2Sm,
        lg: img2,
    },
    {
        base: img3Xs,
        sm: img3Sm,
        lg: img3,
    },
    {
        base: img4Xs,
        sm: img4Sm,
        lg: img4,
    },
    {
        base: img5Xs,
        sm: img5Sm,
        lg: img5,
    },
    {
        base: img6Xs,
        sm: img6Sm,
        lg: img6,
    },
    {
        base: img7Xs,
        sm: img7Sm,
        lg: img7,
    },
    {
        base: img8Xs,
        sm: img8Sm,
        lg: img8,
    },
    {
        base: img9Xs,
        sm: img9Sm,
        lg: img9,
    },
    {
        base: img10Xs,
        sm: img10Sm,
        lg: img10,
    }
];

export default carouselImages;