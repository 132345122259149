const pt = {
    title: 'VAMOS CASAR!',
    subtitle: {
        paragraph1: 'Estamos muito felizes de iniciar essa nova etapa en nossas vidas e queremos compartilhar nossa felicidade com você.',
        paragraph2: 'Com muito carinho te convidamos para o nosso casamento e contamos com sua presença no dia 15 de $t(months.m3) de 2023 às 16h00 no Espaço Abricó.',
    },
    countdown: {
        title: 'Que Comece a Contagem Regressiva!',
        days: 'Dias',
        hours: 'Horas',
        minutes: 'Minutos',
        seconds: 'Segundos',
    },
    destination: {
        title: 'Local',
        subtitle: 'São Sebastião, São Paulo, Brasil',
        paragraph1: 'Apesar de não vivermos no Brasil, decidimos celebrar esse momento no país onde nos conhecemos.',
        paragraph2: 'Escolhemos a praia de São Sebastião para celebrar nossa união já que nosso sonho sempre foi casar em frente ao mar, pé na areia, e o com o entardecer como testemunha.',
        paragraph3: 'São Sebastião á um paraíso aonde se respira tranquilidade, perfeito para desfrutar do contato com a natureza. A região conta com ilhas, cascatas e algumas das mais belas praias da costa de São Paulo.',
    },
    instructions: {
        pageTitle: 'Instruções',
        pageSubtitle: {
            paragraph1: 'Queremos que você esteja tranquilo quanto à opções de transporte e acomodação.',
            paragraph2: 'Aqui você vai encontrar mais informações sobre como chegar e aonde se hospedar.'
        },
        title: 'Como chegar',
        subtitle: {
            paragraph1: 'Se você vem de fora do país, recomendamos tomar um vôo para São Paulo com chegada no <style_anchor>Aeropuerto Internacional de Guarulhos (GRU)</style_anchor>.',
            paragraph2: 'Assim que chegar ao aeroporto, você deve ir para São Sebastião, que está localizado na costa do estado de São Paulo à uma distância aproximada de 3 horas.',
            paragraph3: 'Existem duas formas de chegar à São Sebastião vindo do Aeropuerto Internacional de Guarulhos (GRU).'
        },
        car: {
            title: 'Alugar um Carro',
            paragraph1: 'No aeroporto você pode alugar um carro e dirigir até São Sebastião. Recomendamos que você level dinheiro para pagar os pedágios.',
            paragraph2: 'Saindo do aeroporto, você deve seguir na Rodovia SP-070 (Trabalhadores/Ayrton Senna) e dirigir em direção à São José dos Campos. Depois, você segue pela Rodovia SP-099 (Tamoios) em direção à Caraguatatuba. E por último, siga viagem pela Rodovia BR-101 (Rio-Santos) até chegar a São Sebastião.',
            mapsLink: 'Veja o trajeto no Google Maps',
        },
        other: {
            title: 'Outras opções',
            bus: {
                title: 'De Ônibus',
                paragraph1: ' Para ir de ônibus, você deve seguir até o Terminal 2 Leste do Aeropuerto Internacional de Guarulhos (GRU). Lá você pode comprar sua passagem de ida e volta para São Sebastião na empresa Pássaro Marrom.',
                paragraph2: 'Para ver os horários e preços entre no site:',
                paragraph3: 'Caso você nao esteja no aeroporto e planeja ir de ônibus, recomendamos que você vá até a Rodoviaria do Tietê. Lá você também encontrará ônibus que fazem o trajeto de ida e volta à São Sebastião.',
                paragraph4: 'Você pode ver os horários e preços no site à seguir:',
                paragraph5: 'Outra opção seria de tomar um táxi / Uber até São Sebastião.',
            },
            uber: {
                title: 'Uber / Lyft' ,
                paragraph1: 'Os aplicativos do Uber e Lyft podem te levar direto à São Sebastião.'
             },
             taxi: {
                 title: 'Táxi',
                 paragraph1: 'O Aeroporto de Guarulhos (GRU) oferece serviços de táxi em todos os terminais. Você pode buscar o ponto de táxi mais perto do seu terminal de chegada ou chamar o número abaixo para mais informações:'
             }
        }
    },
    hospitality: {
        title: 'Acomodação',
        airbnb: {
            tab: 'AIRBNB',
            title: 'Uma opção econômica de acomodação seria buscar um AitBnb perto do local onde se celebrará o casamento',
            link: 'Veja as opções de AirBnbs perto do local do casamento',
        },
        hotels:{
            tab: 'HOTÉIS',
            title: 'Veja as opções de hotéis perto do local do casamento',
        },
        inns:{
            tab: 'POUSADAS',
            title: 'Veja as opções de pousadas perto do local do casamento',
        },
        hostels:{
            tab: 'HOSTELS',
            title: 'Veja as opções de hostels perto do local do casamento',
        },
        reservationAvailability: {
            reservations: 'Reservas',
            available: 'já estão disponíveis',
            notAvailable: 'disponíveis a partir de {{month}} de {{year}}'
        },
        howToBook: 'Como reservar',
        actions: {
            sendEmail: 'Enviar Email',
        }
    },
    months: {
        m0: 'janeiro',
        m1: 'fevereiro',
        m2: 'março',
        m3: 'abril',
        m4: 'maio',
        m5: 'junho',
        m6: 'julho',
        m7: 'agosto',
        m8: 'setembro',
        m9: 'outubro',
        m10: 'novembro',
        m11: 'dezembro'
    },
    footer: {
        title: 'Tem algum dúvida?',
        subtitle:  'Estamos aqui:',
    },
    rsvp: {
        form: {
            title: 'CONFIRME SUA PRESENÇA',
            nameLabel: 'Nome',
            numberInviteesLabel: 'Número de pessoas que vão te acompanhar',
            numberInviteesPlaceholder: 'Selecione uma opção',
            submit: 'Confirmar'
        },
        success: 'Obrigado por confirmar sua presença!',
        errors: {
            generic: 'Erro. Por favor tente novamente.',
            unique: 'Já existe uma confirmação com o seu nome!',
            required: 'Preencha o campo',
            formRequired: 'Por favor preencha seu nome e o número de pessoas que vão te acompanhar',
        }
    },
    gifts: {
        title: 'Presentes',
        subtitle: {
            paragraph1: 'A sua presença no nosso casamento é o maior presente de todos!',
            paragraph2: 'Caso queira nos presentear, como não moramos no Brasil, levar os presentes pra casa sairía muito caro por causa do excesso de bagagem.',
            paragraph3: 'Por isso, preferimos receber os presentes em dinheiro.',
        },
        howToTitle: 'Como você pode nos presentear',
        howToSubtitle: 'Selecione o país aonde você mora',
        options: {
            title: 'Opções',
            cash: {
                title: 'Em dinheiro',
                content: 'Você pode nos entregar em mãos no dia da festa.',
            },
            pix: {
                title: 'PIX',
                content: 'Chave PIX',
            },
            transferSoles: {
                title: 'Transferência em Sóis',
                content: 'Número da conta',
            },
            transferDollars: {
                title: 'Transferência en dólares',
                content: 'Número da conta',
            },
            zelle: {
                title: 'Zelle',
                content: 'Número de telefone',
            }
        },
        bank: 'Banco',
    },
    countries: {
        'br': 'Brasil',
        'pe': 'Peru',
        'us': 'EUA',
        'others': 'Outros',
    },
    nav: {
        home: 'Home',
        rsvp: 'RSVP',
        gifts: 'Presentes',
        language: 'Idioma'
    }
}
export default pt;