import { useTranslation } from "react-i18next";
import { Heading, Text } from "components/common";
import { HowToGetThere, Lodging } from "components/instructions";

const Instructions = () => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col space-between bg-white">
            <div className="basis-2/6 bg-main-background w-full text-center pb-12">
                <div className="px-12 pt-8 inline-block">
                    <Heading
                        className="text-3xl sm:text-4xl md:text-5xl text-center"
                        tag="h2"
                    >
                        <div className="border-b-4 border-main-solid ">
                            <span className="pb-2">
                                {t('instructions.pageTitle')}
                            </span>
                        </div>
                    </Heading>
                    <div className="mt-8 ml-1.5 text-center">
                        <Text className="mb-4 leading-8 text-lg sm:text-xl opacity-75">
                            {t('instructions.pageSubtitle.paragraph1')}
                        </Text>
                        <Text className="mb-4 leading-8 text-lg sm:text-xl opacity-75">
                            {t('instructions.pageSubtitle.paragraph2')}
                        </Text>
                    </div>
                </div>
            </div>
            <div>
                <HowToGetThere />
                <Lodging />
            </div>
        </div>
    )
}

export default Instructions;