import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, LanguageSelectorModal } from "components/common"
import { Nav } from "components/layout"

const Header = () => {
    const { i18n } = useTranslation();
    const [showLanguageModal, setShowLanguageModal] = useState(!i18n.language)
    const handleLanguageChange = (newLang) => {
        i18n
            .changeLanguage(newLang)
            .then(() => {
                setShowLanguageModal(false);
            })
      }

    const openLanguageModal = () => setShowLanguageModal(true);
    const closeLanguageModal = () => setShowLanguageModal(false);

    return (
        <header className="App-header">
            <Heading
                className="text-4xl sm:text-5xl md:text-6xl text-center pb-4 sm:pb-6 pt-8"
                tag="h1"
            >
               Brenda & Cauê
            </Heading>
            <Nav openLanguageModal={openLanguageModal}/>
            <LanguageSelectorModal
                show={showLanguageModal}
                close={closeLanguageModal}
                onLanguageChange={handleLanguageChange}
                currentLanguage={i18n.language}
            />
        </header>
    )
}

export default Header