import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Heading, Text } from 'components/common';
import {
    FlagBrazil,
    FlagPeru,
    FlagUSA,
    EarthIcon,
    PixIcon,
    DollarSignIcon,
    ZelleIcon,
    MoneyTransferIcon,
} from 'vectors'

const CountryOption = ({countryData, isSelected, onSelect, t}) => {
    return (
        <button
            key={countryData.code}
            type="button"
            className={classNames(
                "flex flex-col justify-center items-center w-28 bg-white shadow-xl border border-gray-400 mx-2 mb-6 sm:mb-0 rounded-3xl h-28 focus:outline-none",
                {
                    'text-gray-600 hover:opacity-80': !isSelected,
                    'text-blue-700 border-blue-600 border-2 font-bold': isSelected,
                }
            )}
            onClick={() => onSelect(countryData.code)}
        >
            <div className="rounded-full overflow-hidden h-12 w-12 border-0">
                <countryData.Flag />
            </div>
            <Heading className="text-xs font-bold tracking-wider pt-4">
                {t(countryData.name)}
            </Heading>
        </button>
    )
}

const GiftOption = ({title, Icon, iconColor, children}) => {
    return (
        <div className="basis-3/12">
            <div className={`h-12 w-12 mx-auto ${iconColor || ''}`}>
                <Icon />
            </div>
            <Heading className="text-md sm:text-lg md:text-xl my-4">
                {title}
            </Heading>
            {children}
        </div>
    )
}

const CashGiftOption = ({t}) => {
    return (
        <GiftOption
            title={t('gifts.options.cash.title')}
            Icon={DollarSignIcon}
        >
            <Text className="mb-6 tracking-wide leading-7 text-lg opacity-75">
                {t('gifts.options.cash.content')}
            </Text>
        </GiftOption>
    )
}

const PixGiftOption = ({t}) => {
    return (
        <GiftOption
            title={t('gifts.options.pix.title')}
            Icon={PixIcon}
        >
            <Text className="tracking-wide leading-7 text-lg opacity-75">
                <span className="font-bold">{t('gifts.options.pix.content')}</span>
            </Text>
            <Text className="mb-6 tracking-wide leading-7 text-lg opacity-75">
                cauesilvadev@gmail.com
            </Text>
        </GiftOption>
    )
}

const ZelleGiftOption = ({t}) => {
    return (
        <GiftOption
            title={t('gifts.options.zelle.title')}
            Icon={ZelleIcon}
        >
            <Text className="tracking-wide leading-7 text-lg opacity-75">
                <span className="font-bold">{t('gifts.options.zelle.content')}</span>
            </Text>
            <Text className="mb-6 tracking-wide leading-7 text-lg opacity-75">
                (786) 843-2485
            </Text>
        </GiftOption>
    )
}

const TransferInSolesGiftOption = ({t}) => {
    return (
        <GiftOption
            title={t('gifts.options.transferSoles.title')}
            Icon={MoneyTransferIcon}
            iconColor="text-green-500"
        >
            <Text className="tracking-wide leading-7 text-lg opacity-75">
                <span className="font-bold">{t('gifts.bank')}</span>
            </Text>
            <Text className="tracking-wide leading-7 text-lg opacity-75 mb-3">
                Scotiabank
            </Text>
            <Text className="tracking-wide leading-7 text-lg opacity-75">
                <span className="font-bold">{t('gifts.options.transferSoles.content')}</span>
            </Text>
            <Text className="mb-6 tracking-wide leading-7 text-lg opacity-75">
                1550113466
            </Text>
        </GiftOption>
)
}

const TransferInDollarsGiftOption = ({t}) => {
    return (
        <GiftOption
            title={t('gifts.options.transferDollars.title')}
            Icon={MoneyTransferIcon}
            iconColor="text-green-500"
        >
            <Text className="tracking-wide leading-7 text-lg opacity-75">
                <span className="font-bold">{t('gifts.bank')}</span>
            </Text>
            <Text className="tracking-wide leading-7 text-lg opacity-75 mb-3">
                BBVA
            </Text>
            <Text className="tracking-wide leading-7 text-lg opacity-75">
                <span className="font-bold">{t('gifts.options.transferDollars.content')}</span>
            </Text>
            <Text className="mb-6 tracking-wide leading-7 text-lg opacity-75">
                0011 0814 0218769883
            </Text>
        </GiftOption>
    )
}
const getGiftOptionsByCountry = (countries, countryCode) => {
    const country = countries.filter(country => country.code === countryCode);
    return country.length ? country[0].giftOptions : [];
}

const countries = [
    {
        name: 'countries.br',
        code: 'br',
        Flag: FlagBrazil,
        giftOptions: [CashGiftOption, PixGiftOption],
    },
    {
        name: 'countries.pe',
        code: 'pe',
        Flag: FlagPeru,
        giftOptions: [CashGiftOption, TransferInSolesGiftOption, TransferInDollarsGiftOption]
    },
    {
        name: 'countries.us',
        code: 'us',
        Flag: FlagUSA,
        giftOptions: [CashGiftOption, ZelleGiftOption],
    },
    {
        name: 'countries.others',
        code: 'others',
        Flag: EarthIcon,
        giftOptions: [CashGiftOption],
    }
];

const Gifts = () => {
    const { t } = useTranslation();
    const [selectedCountry, setSelectedCountry] = useState(null);

    const handleCountrySelected = (country) => {
        setSelectedCountry(country);
    }

    return  (
        <div className="flex flex-col space-between bg-white">
            <div className="basis-2/6 bg-main-background w-full text-center pb-12">
                <div className="px-12 pt-8 inline-block">
                    <Heading
                        className="text-3xl sm:text-4xl md:text-5xl text-center"
                        tag="h2"
                    >
                        <div className="border-b-4 border-main-solid ">
                            <span className="pb-2">
                                {t('gifts.title')}
                            </span>
                        </div>
                    </Heading>
                </div>
            </div>
            <div className="basis-2/6 flex flex-col px-12 mx-auto max-w-70vh md:max-w-80vh lg:max-w-80vh">
                <div className="mt-10 ml-1.5 text-center">
                    <Text className="mb-4 leading-8 text-lg sm:text-xl opacity-75">
                        {t('gifts.subtitle.paragraph1')}
                    </Text>
                    <Text className="mb-4 leading-9 text-lg sm:text-xl opacity-75">
                        {t('gifts.subtitle.paragraph2')}
                    </Text>
                    <Text className="leading-8 text-lg sm:text-xl opacity-75">
                        {t('gifts.subtitle.paragraph3')}
                    </Text>
                </div>
            </div>
            <div className="flex flex-col px-12 pb-32 mt-12 mx-auto w-full text-center bg-main-background">
                <Heading
                    className="text-2xl sm:text-3xl md:text-4xl mb-10 pb-2 pt-12 mt-6"
                    tag="h2"
                >
                    {t('gifts.howToTitle')}
                </Heading>
                <Text className="mb-6 tracking-wide leading-7 text-2xl opacity-75">
                    {t('gifts.howToSubtitle')}
                </Text>
                <div className="pt-2  w-full max-w-70vh md:max-w-80vh lg:max-w-80vh mx-auto">
                    <div className="flex flex-row flex-wrap justify-around">
                        {countries.map((country) => (
                           <CountryOption
                                key={country.code}
                                countryData={country}
                                isSelected={country.code === selectedCountry}
                                onSelect={handleCountrySelected}
                                t={t}
                            />
                        ), this)}
                    </div>
                </div>
                {!!selectedCountry && (
                    <div className="w-full max-w-90vh mx-auto mt-16">
                        <div className="bg-white rounded-lg pt-8 pb-4 px-4 shadow-lg">
                            <Heading
                                className="text-xl sm:text-2xl md:text-3xl mb-6 sm:mb-10 pb-2"
                                tag="h2"
                            >
                                {t('gifts.options.title')}
                            </Heading>
                            <div className="flex flex-col sm:flex-row justify-around gap-8 sm:gap-6">
                                {getGiftOptionsByCountry(countries, selectedCountry).map((Option, i) => (
                                    <Option key={i} t={t}/>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Gifts;