import { useTranslation } from 'react-i18next';

import { Heading, Text } from 'components/common';
import { WhatsappIcon } from 'vectors';


const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer className="border-t-2 border-main-solid pb-2 bg-white">
            <div className="text-center">
                <Heading
                    className="text-2xl sm:text-3xl md:text-4xl mb-10 mt-16 mx-12"
                    tag="h2"
                >
                    {t(`footer.title`)}
                </Heading>
                <Heading
                    className="text-lg sm:text-xl md:text-2xl mt-3 mb-8 mx-12"
                    tag="h3"
                >
                    {t(`footer.subtitle`)}
                </Heading>
                <div className="flex flex-row align-center justify-evenly max-w-xs mx-auto">
                    <a
                        href="https://api.whatsapp.com/send?phone=17869850810&amp;text="
                        className="text-center flex flex-col justify-center items-center h-20"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <WhatsappIcon/>
                        <Heading className="text-sm text-gray-500 font-bold mt-1.5">
                            Brenda
                        </Heading>
                    </a>
                    <a
                        href="https://api.whatsapp.com/send?phone=17868432485&amp;text="
                        className="text-center flex flex-col justify-center items-center h-20"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <WhatsappIcon/>
                        <Heading className="text-sm text-gray-500 font-bold mt-1.5">
                            Caue
                        </Heading>
                    </a>
                </div>
            </div>
            <div className="mt-20 pb-24 sm:pb-5 text-center">
                <Text className="text-sm font-semibold opacity-75">
                    © Copyright 2022 Caue Silva
                </Text>
            </div>
        </footer>
    )
}

export default Footer