import { FlagBrazil, FlagPeru, FlagUSA, EarthIcon } from "vectors"

export function getLanguageIconByCode(code) {
    const iconMapper = {
        'es': FlagPeru,
        'en': FlagUSA,
        'pt': FlagBrazil,
        'DEFAULT': EarthIcon
    }

    return iconMapper[code] || iconMapper.DEFAULT;
}
