import { Routes, Route, Navigate } from 'react-router-dom'
import Home from 'pages/Home'
import Rsvp from 'pages/Rsvp'
import Gifts from 'pages/Gifts'
import Instructions from 'pages/Instructions'
import ApplicationLayout from 'layouts/ApplicationLayout'

const App = () => {
    return (
        <ApplicationLayout>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/rsvp" element={<Rsvp />} />
                <Route path="/gifts" element={<Gifts />} />
                <Route path="/instructions" element={<Instructions />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </ApplicationLayout>
    )
}
export default App