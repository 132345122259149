import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading, Text, ImageSrcSet } from 'components/common';
import { ImagesCarrousel, Countdown, Destination } from 'components/home';
import { HowToGetThere, Lodging } from 'components/instructions';
import { carouselImages } from 'constants'

const Home = () => {
  const { t, i18n } = useTranslation();
  const weddingDateISO = '2023-04-15T16:00:00.000-03:00'
  const [tick, setTick] = useState(0)
  const interval = useRef(null)

  useEffect(() => {
      if (!interval.current) {
          interval.current = setInterval(() => {
              setTick(prevCount => 1 - prevCount)
          }, 1000)
      }
  }, [])

  return (
    <>
      <div className="sm:mt-8">
          <ImagesCarrousel
            images={carouselImages}
            ImageComponent={ImageSrcSet}
            tick={tick}
        />
      </div>
      {!!i18n.language && (
        <>
            <div className="flex flex-col space-between">
                <div className="basis-2/6 flex flex-col px-12 my-20 mx-auto max-w-70vh md:max-w-80vh lg:max-w-80vh">
                    <Heading
                        className="text-3xl sm:text-4xl md:text-5xl text-center mb-2"
                        tag="h2"
                    >
                        <div className="border-b-4 border-main-solid ">
                            <span className="pb-2">
                                {t('title')}
                            </span>
                        </div>
                    </Heading>
                    <div className="mt-8 ml-1.5 text-center">
                        <Text className="mb-4 leading-8 text-lg sm:text-xl opacity-75">
                            {t('subtitle.paragraph1')}
                        </Text>
                        <Text className="mb-4 leading-8 text-lg sm:text-xl opacity-75">
                            {t('subtitle.paragraph2')}
                        </Text>
                        <Text className="text-lg sm:text-xl opacity-75">
                            <a
                                href="https://goo.gl/maps/xz5i7oAUyTwPQ7CH9"
                                className="text-center leading-8  underline underline-offset-4"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Rod. Dr. Manoel Hipólito do Rêgo, 2354 - Praia do Arrastão, São Sebastião - SP, 11605-136, Brasil
                            </a>
                        </Text>
                    </div>
                </div>
            </div>
            <div className="text-center bg-white py-12">
                <Heading
                    className="text-2xl sm:text-3xl md:text-4xl mb-12 mx-12"
                    tag="h2"
                >
                    {t(`countdown.title`)}
                </Heading>
                <div className="w-full md:w-75vh sm:mx-auto">
                    <Countdown
                        dateISOString={weddingDateISO}
                        tick={tick}
                    />
                </div>
            </div>
            <div>
                <Destination />
                <HowToGetThere />
                <Lodging />
            </div>
        </>
    )}
    </>
  );
}

export default Home